<template>
    <div class="backImg">
        <div class="pt15 h80">
            <div class="pr390 pl370 rowjbic">
                <img class="w113" style="object-fit: fill;" src="@/assets/img/img/logo.png" alt="">
                <div class="rowic cfff">
                    <div v-for="item in topList" class="ml100 fs20  hands" :key="item.id"
                        @click="goInfoPage(item.path)">
                        <div v-if="item.id==2">
                            <el-dropdown @command="command">
                                <span class="el-dropdown-link">
                                    <span class="cfff fs20"> {{item.name}}</span>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="1">商家入驻</el-dropdown-item>
                                    <el-dropdown-item command="2">医院入驻</el-dropdown-item>
                                    <el-dropdown-item command="3">药店入驻</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div v-else>
                            {{ item.name }}
                        </div>
                    </div>

                </div>
            </div>
            <div>

            </div>
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'top',
        data() {
            return {
                topList: [
                    {
                        id: 1,
                        name: '首页',
                        path: '/'
                    },
                    {
                        id: 2,
                        name: '联盟入驻',
                        path:'null'
                        
                    },
                    {
                        id: 3,
                        name: '新闻咨询',
                        path:'/news'
                    }, {
                        id: 4,
                        name: '人才招聘',
                        path:"/recruit"
                    }, {
                        id: 5,
                        name: '关于我们',
                        path:"/about"
                    }

                ]
            }

        },
        methods: {
            command(id) {//商家入驻
                if (id == 1) {
                   
                   
                    this.$router.push('/business')
                }
                if (id == 2) {
                    
                    this.$router.push('/hospital')
                }
                if (id == 3) {
                  
                    this.$router.push('/parmacy')
                }
                

            },
            goInfoPage(path) {//跳转详情页面
                if(path=='null'){
                    return false
                }
                this.$router.push(path)
            }
        },
    }
</script>
<style>
    .backImg {
        background-image: url(../assets/img/img/banner.png);
        width: 100%;
        height: 550px;
        object-fit: fill;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .hands{
        opacity: 0.88;
    }

    .hands:hover {
        cursor: pointer;
        opacity: 1;
       
    }
</style>
<template>
    <div class="bottom rowic cfff pl380 pr400 rowjbic">
        <div class="h80 crowjb ">
          <div class=" rowic" >
            <div class="mr40 rightBorder hands" @click="$router.push(item.path)"  v-for="(item,index) in list" :key="index">{{item.name}}</div>
          </div>
            <div class="rowic">
                <div class="mr20"> 安迩健康网© 2006-2021</div>
                <div class="mr20">鲁ICP备05016296号-4</div>
                <div class="mr20">鲁公网安备11010502041409号</div>
            </div>
        </div>
        <div class="crowic ">
            <img src="@/assets/img/img/erweima.png" alt="">
            <div class="fs10 mt10">扫码下载安迩健康APP</div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'bottom',
        data() {
            return {
                list:[
                    {
                        id:1,
                        name:"首页",
                        path:'/'
                    },
                    {
                        id:2,
                        name:"联盟入驻",
                        path:'/business'
                    },
                    {
                        id:3,
                        name:"新闻咨询",
                        path:'/news'
                    },
                    {
                        id:4,
                        name:"人才招聘",
                        path:'/recruit'
                    },
                    {
                        id:5,
                        name:"关于我们",
                        path:'/about'
                    }
                ]
            }
        },

    }
</script>
<style>
    .bottom {
       width: 100%;
        height: 200px;
        background: #373232;
    }
    .rightBorder:not( :last-child){
        border-right: 3px solid #fff;
        padding-right: 40px;
        font-size: 16px;
    }
    .hands{
        cursor: pointer;
    }
</style>
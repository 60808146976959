<template>
    <div>
        <top>
            <div class="tc cfff">
                <div class="fs42 pt140">新闻咨询</div>
                <div class="fs20 mt20 " style="opacity: 0.8;">有关安迩平台新闻资讯</div>
            </div>
        </top>
        <div class="rowjc" style="margin-top: -130px;">
            <div class="box" style="padding: 0px 40px; min-height: calc(100vh - 670px);" >
                <div v-for="(item,index) in newList" :key="index" class="rowic bottomQ" @click="goInfoPage(item.id)"
                    style=" width:100%;height: 200px;padding: 24px 0;">
                    <div class="w200 h140 br8 mw200 " style="background-color: #FFFFFF;"><img
                            :src="item.image" alt="加载失败" class="mw200 h140" style="background-size: 100% 100%; background-repeat: no-repeat;"></div>
                    <div class="ml30 h136 crowjb">
                        <div style="color: #1DB9DF; width: 800px;" class="fs18 fwb yc1 ">{{item.title}}</div>
                        <div class=" yc3"  style="width: 880px; color: #646464;font-size: 16px;">
                          {{item.synopsis}}
                        </div>
                        <!-- <div class="tr fs14" style=" color: #646464;">查看详情</div> -->
                    </div>
                </div>
            </div>
        </div>
        <div style="height:60px;"></div>
        <bottom></bottom>
    </div>
</template>
<script>
    import top from '@/components/top'
    import bottom from '@/components/common/bottom'
    export default {
        components: {
            bottom, top
        },
        data() {
            return {
                newList:[],//新闻列标
            }
        },
        mounted() {
            this.getNewsList()//获取新闻列表
        },
        methods: { 
            goInfoPage(id){
                this.$router.push({
                    path:'/newsInfo',
                    query:{
                        id
                    }
                })
            },
            getNewsList() {//获取列表新闻
                this.$axios({
                    url: 'getMessageList',
                    data: {
                        type: 1
                    }

                }).then(res => {

                    if (res.data.code == 0) {
                        this.newList = res.data.data

                    } else {
                        this.newList = []
                    }
                })
            },
        },
    }
</script>
<style>
    #app {
        background-color: #F2F5F7;
    }

    .box {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 20px;

    }
    .bottomQ:not(:last-child){
        border-bottom: 1px dashed #D7D7D7;
    }
    .bottomQ:hover{
        cursor: pointer;
    }
</style>